import { useLocation, matchPath } from 'react-router-dom';
export default function useActiveLink(path, deep) {
    if (deep === void 0) { deep = true; }
    var pathname = useLocation().pathname;
    var normalActive = path ? !!matchPath({ path: path, end: true }, pathname) : false;
    var deepActive = path ? !!matchPath({ path: path, end: false }, pathname) : false;
    return {
        active: deep ? deepActive : normalActive,
        isExternalLink: path.includes('http'),
    };
}
